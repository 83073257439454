const componentProperties = new Map()

// 数据版本  每次修改组件数据  需要对版本进行修改
// componentProperties.set('componentPropertiesVersion', 'V1.0.0')

componentProperties.set('captiontext', {
    component: 'captiontext',
    text: '标题文字',
    type: '1-3',
    active: true,
    style: 'associationCaptiontextsstyle',
    setStyle: {
        text: '标题文字',
        name: '标题文字', //标题内容
        description: '', //描述内容
        wordSize: 16, //标题大小
        descriptionSize: 12, //描述大小
        wordWeight: 400, //标题粗细
        positions: 'left', //显示位置  可选left/center
        descriptionWeight: 200, //描述粗细
        wordColor: 'rgba(50, 50, 51, 10)', //标题颜色
        descriptionColor: 'rgba(150, 151, 153, 10)', //描述颜色
        backColor: 'rgba(255, 255, 255, 10)', //背景颜色
        borderBott: false, //底部分割线
        wordHeight: 24, //框体高度
        more: { //查看更多
            show: false, //是否显示查看更多
            type: 1, // 样式选择
            text: '查看更多', //自定义文字
            http: {}, //链接
        },
    },
})

componentProperties.set('pictureads', {
    component: 'pictureads',
    text: '图片广告',
    type: '1-3',
    active: true,
    style: 'associationPictureadsstyle',
    setStyle: {
        text: '图片广告',
        swiperType: 1, // 选择模板
        borderRadius: 4, // 图片倒角
        pageMargin: 0, // 页面边距
        imageMargin: 0, // 图片边距
        pagingType: 'bullets', // 分页类型: 0/"bullets"/"fraction"/"progressbar"
        rowindividual: 2, // 一行个数
        imgHeight: 220, //轮播图高度
        intervalDate: 6000,
        imageList: [], // 添加图片
        modelType: "", //空为默认 leftRightFull左右铺满 allFull全屏铺满
    },
})

componentProperties.set('distributorRecord', {
    component: 'distributorRecord',
    text: '会员中心',
    type: '3-1',
    active: true,
    style: 'associationDistributorRecordstyle',
    setStyle: {
        text: '会员中心',
        // imageList: [], // 图片导航列表
        // navigationType: 0, //图片导航类型 0图片导航 1文字导航
        // imgStyle: 0, //图片样式 0固定 1滑到
        backgroundColor: 'rgb(255, 255, 255)', //背景颜色
        textColor: 'rgb(0, 0, 0)', //文字颜色
        // iconRadius: 0, //图片倒角 百分比
        // showSize: 5, //一屏显示个数
        textHeight: 24, // 字体高度
        textSize: 12, // 字体大小
        bgImg: '',
        title: "会员中心", //标题
        showTitle: true, //是否显示标题
        showMore: true, //是否显示更多
        showEwm: true, //是否显示分享码
        isShadow: true,
        btnColor: "#739ae9",
        // imgSize: 45, //图片大小
        // moreHttp: {}, //更多的跳转链接
        // rowCount: 5, //一行条数
        borderRadius: 0, //边框倒角
    },
})

componentProperties.set('productType', {
    component: 'productType',
    text: '商品分类',
    type: '1-4',
    active: true,
    style: 'associationProductTypestyle',
    setStyle: {
        text: '商品分类',
        imageList: [], // 图片导航列表
        navigationType: 0, //图片导航类型 0图片导航 1文字导航
        imgStyle: 0, //图片样式
        backgroundColor: 'rgb(255, 255, 255)', //背景颜色
        textColor: 'rgb(0, 0, 0)', //文字颜色
        iconRadius: 0, //图片倒角 百分比
        showSize: 5, //一屏显示个数
        textHeight: 24, // 字体高度
        textSize: 14, // 字体大小
        bgImg: '',
        title: "商品分类", //标题
        showTitle: true, //是否显示标题
        showMore: true, //是否显示更多
        isShadow: true,
        imgSize: 45, //图片大小
        moreHttp: {}, //更多的跳转链接
        rowCount: 5, //一行条数
        borderRadius: 0, //边框倒角
        isLogin: false,
        isCard: false,

        autoData: [], //自动获取的数据
        addMethod: "auto", //auto 自动获取 manual 手动获取
    },
})
componentProperties.set('graphicnavigation', {
    component: 'graphicnavigation',
    text: '图文导航',
    type: '1-4',
    active: true,
    style: 'associationGraphicnavigationstyle',
    setStyle: {
        text: '图文导航',
        imageList: [], // 图片导航列表
        navigationType: 0, //图片导航类型 0图片导航 1文字导航
        imgStyle: 0, //图片样式
        backgroundColor: 'rgb(255, 255, 255)', //背景颜色
        textColor: 'rgb(0, 0, 0)', //文字颜色
        iconRadius: 0, //图片倒角 百分比
        showSize: 5, //一屏显示个数
        textHeight: 24, // 字体高度
        textSize: 14, // 字体大小
        bgImg: '',
        title: "图文导航", //标题
        showTitle: true, //是否显示标题
        showMore: true, //是否显示更多
        isShadow: true,
        imgSize: 45, //图片大小
        moreHttp: {}, //更多的跳转链接
        rowCount: 5, //一行条数
        borderRadius: 0, //边框倒角
        isLogin: false,
        isCard: false,
    },
})

componentProperties.set('richtext', {
    component: 'richtext',
    text: '富文本',
    type: '1-10',
    active: true,
    style: 'richtextstyle',
    setStyle: {
        text: '富文本',
        myValue: '', //富文本内容
        backColor: 'rgb(249, 249, 249)', //背景颜色
    },
})
componentProperties.set('magiccubeCommon', {
    component: 'magiccubeCommon',
    text: '魔方',
    type: '1-6',
    active: true,
    style: 'associationMagiccubeCommonstyle',
    setStyle: {
        text: '魔方',
        rubiksCubeType: 0, // 魔方类型
        pageMargin: 0, //页面间距
        imgMargin: 0, //图片间隙
        imgHeight: 160,
        imageList: [], //图片列表
    },
})
componentProperties.set('magiccube', {
    component: 'magiccube',
    text: '会员套餐',
    type: '2-5',
    active: true,
    style: 'associationMagiccubestyle',
    setStyle: {
        text: '会员套餐',
        imgHeight: 160,
        rubiksCubeType: 0, // 魔方类型
        pageMargin: 0, //页面间距
        imgMargin: 0, //图片间隙
        imageList: [], //图片列表

        autoData: [], //自动获取的数据
        addMethod: "auto", //auto 自动获取 manual 手动获取
        dataNumber: 2
    },
})
componentProperties.set('auxiliarysegmentation', {
    component: 'auxiliarysegmentation',
    text: '辅助分割',
    type: '1-11',
    active: true,
    style: 'auxiliarysegmentationstyle',
    setStyle: {
        text: '辅助分割',
        blankHeight: 10, //空白高度
        segmentationtype: 0, //分割类型
        paddType: 0, //边距
        auxliarColor: 'rgb(229, 229, 229)', //辅助线颜色
        bordertp: 'solid', //线的类型
    },
})
componentProperties.set('homeCommoditysearch', {
    component: 'homeCommoditysearch',
    text: '首页搜索',
    type: '1-1',
    active: true,
    style: 'homeCommoditysearchstyle',
    setStyle: {
        showSweep: true, // 显示扫一扫
        showMessage: true, // 显示提醒消息
        showSwitch: true, // 显示社团切换
        borderRadius: 3, //框体倒角
        searchPlaceholder: "搜索动态/活动/企业/供求",
        borderColor: 'rgba(55, 84, 140, 0.9)', //框体颜色
        textColor: '#fff', //字体颜色
    },
})
componentProperties.set('commoditysearch', {
    component: 'commoditysearch',
    text: '搜索',
    type: '1-1',
    active: true,
    style: 'commoditysearchstyle',
    setStyle: {
        text: '搜索',
        heights: 28, //搜索栏高度
        position: 0, //显示位置
        sweep: false, // 显示扫一扫
        borderRadius: 0, //框体样式
        textPosition: 0, //文本位置
        backgroundColor: 'rgb(249, 249, 249)', //背景颜色
        borderColor: 'rgb(255, 255, 255)', //框体颜色
        textColor: 'rgb(150, 151, 153)', //字体颜色
        hotords: [], //热词
    },
})

componentProperties.set('storeinformation', {
    component: 'storeinformation',
    text: '店铺信息',
    type: '1-12',
    active: true,
    style: 'storeinformationstyle',
    setStyle: {
        text: '店铺信息',
        bakcgroundImg: '', //背景图片
        headPortrait: '', //店铺头像
        rubiksCubeType: 0, //类型
        name: '店铺名称', //店铺名称
        Discount: '在线支付满150减30，满100减20', //优惠信息
    },
})

componentProperties.set('entertheshop', {
    component: 'entertheshop',
    text: '单元格',
    type: '1-13',
    active: true,
    style: 'associationEntertheshopstyle',
    setStyle: {
        text: '单元格',
        icon: '', // 左侧图标
        shopName: '左侧标题',
        copywriting: '右侧内容',
        type: '10',
        isShadow: true,
        http: {},
    },
})

componentProperties.set('notice', {
    component: 'notice',
    text: '公告',
    type: '1-7',
    active: true,
    // style: 'noticestyle',
    style: 'associationNoticestyle',
    setStyle: {
        title: "公告",
        text: '公告',
        // noticeText: '请填写内容，将会在手机上滚动显示', //内容
        backColor: '#fff', //背景颜色
        textColor: 'rgba(100, 101, 102)', //文字颜色
        titleColor: "#d34e4f",
        showType: "leftRightRoll", //显示类型
        isItalic: false,

        activity: [],

        autoData: [], //自动获取的数据
        addMethod: "auto", //auto 自动获取 manual 手动获取
        dataNumber: 3,
    },
})

componentProperties.set('videoss', {
    component: 'videoss',
    text: '视频',
    type: '1-8',
    active: true,
    style: 'videostyle',
    setStyle: {
        text: '视频',
        src: '', // 视频地址
        coverUrl: "", // 封面地址
        autoplay: false, // 是否自动播放
        videoHeight: 200,
        borderRadius: 10,
    },
})

componentProperties.set('custommodule', {
    component: 'custommodule',
    text: '自定义模块',
    type: 'demo',
    active: true,
    style: 'custommodulestyle',
    setStyle: {
        text: '自定义模块',
        demo: '自定义内容',
        img: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/photo/20221101/1d066f268a2971715e33d9ac7b6b11c9.png',
        imgHeight: 90
    },
})

componentProperties.set('communitypowder', {
    component: 'communitypowder',
    text: '社群涨粉',
    type: '1-14',
    active: true,
    style: 'communitypowderstyle',
    setStyle: {
        text: '社群涨粉',
        mainImg: '', //入口图片
        qrcodeImg: '', //二维码
        title: '标题', //标题
        describe: '辅助描述', //描述
        buttonName: '立即添加', //按钮名称
        backColor: 'rgb(255, 255, 255)', //背景颜色
    },
})

componentProperties.set('listswitching', {
    component: 'listswitching',
    text: '商品',
    type: '2-1',
    active: true,
    style: 'associationListswitchingstyle',
    setStyle: {
        text: '商品',
        name: "推荐商品",
        commodityType: 0,
        moditystyle: 1,
        borderRadius: 0,
        pageMargin: 0,
        commodityMargin: 10,
        textWeight: 600,
        positions: 'left',
        priceofcommodity: true,
        purchasebutton: true,
        commoditycorner: true,
        purchasebuttonType: 0,
        commoditycornertype: 0,
        commodityTagColor: '#07c160',
        tagPosition: 0,
        purchase: '马上抢',
        commoditylisttype: 0,
        tabColor: 'rgba(81, 205, 203, 1)',
        moreUrl: null,
        bgImg: '',
        showMore: true,
        isPage: false,
        listTypeDataNumber: 4,
        bannerData: [],
        commoditylisttypetab: [],
        showType: 0,
        prodType: 0,

        autoData: [],
        manualData: [],
        dataNumber: 4,
        addMethod: "auto", //auto 自动获取 manual 手动获取
    },
})

componentProperties.set('activity', {
    component: 'activity',
    text: '活动',
    type: '2-4',
    active: true,
    style: 'associationActivitystyle',
    setStyle: {
        text: '活动',
        name: '社团活动', //专题名称
        commodityType: 4, // 选择模板
        moditystyle: 1, // 卡片样式选择
        borderRadius: 0, // 图片边角
        textWeight: 400, // 标题粗细
        address: true, // 报名人数
        releaseTime: true, // 发布活动时间
        viewMore1: true, //更多1
        viewMore2: false, //更多2
        positions: 'bottom', //标题位置
        http: {},

        isPage: false,
        autoData: [], //自动获取的数据
        manualData: [], //手动添加的数据
        addMethod: "auto", //auto 自动获取 manual 手动获取
        dataNumber: 3
    },
})

componentProperties.set('newsarticle', {
    component: 'newsarticle',
    text: '新闻资讯',
    type: '2-5',
    active: true,
    style: 'associationNewsarticlestyle',
    setStyle: {
        text: '新闻资讯',
        name: '新闻资讯', //专题名称
        commodityType: 3, // 选择模板
        moditystyle: 1, // 卡片样式选择
        borderRadius: 0, // 图片边角
        textWeight: 400, // 标题粗细
        releaseTime: true, //发布时间
        viewMore1: true, //更多1
        viewMore2: false, //更多2
        positions: 'bottom', //标题位置
        http: {},

        isPage: false,
        autoData: [], //自动获取的文章数据
        manualData: [], //手动添加的文章数据
        articleType: "", //文章类型
        addMethod: "auto", //auto 自动获取 manual 手动获取
        dataNumber: 3,
    },
})

componentProperties.set('memberStyle', {
    component: 'memberStyle',
    text: '会员风采',
    type: '2-5',
    active: true,
    style: 'associationMemberStylestyle',
    setStyle: {
        text: '会员风采',
        name: '会员风采', //专题名称
        borderRadius: 0, // 图片边角
        viewMore1: true, //更多1
        viewMore2: false, //更多2
        http: {},
        pageMargin: 10,

        autoData: [], //自动获取的文章数据
        manualData: [], //手动添加的文章数据
        articleType: "", //文章类型
        addMethod: "auto", //auto 自动获取 manual 手动获取
        dataNumber: 3,
    },
})

componentProperties.set('storenotecard', {
    component: 'storenotecard',
    text: '文章模块',
    type: '2-2',
    active: true,
    style: 'storenotecardstyle',
    setStyle: {
        text: '文章模块',
        name: '这里显示专题名称', //专题名称
        commodityType: 0, // 选择模板
        moditystyle: 0, // 卡片样式选择
        borderRadius: 0, // 图片边角
        textWeight: 400, // 标题粗细
        noteLabels: true, // 笔记标签
        readingNumber: true, // 阅读数
        praisePoints: true, //点赞数
        viewMore1: true, //更多1
        viewMore2: false, //更多2
        imageList: [],
        positions: 'bottom', //标题位置
        urlType: '10',
        http: {},
    },
})

componentProperties.set('crowdoperation', {
    component: 'crowdoperation',
    text: '人群运营',
    type: '1-17',
    active: true,
    style: 'crowdoperationstyle',
    setStyle: {
        text: '人群运营',
    },
})

componentProperties.set('personalizedrecommendation', {
    component: 'personalizedrecommendation',
    text: '个性化推荐',
    type: '1-18',
    active: true,
    style: 'personalizedrecommendationstyle',
    setStyle: {
        text: '个性化推荐',
    },
})

componentProperties.set('onlineservice', {
    component: 'onlineservice',
    text: '在线客服',
    type: '1-19',
    active: true,
    style: 'onlineservicestyle',
    setStyle: {
        text: '在线客服',
    },
})

componentProperties.set('investigate', {
    component: 'investigate',
    text: '表单模块',
    type: '2-3',
    active: true,
    style: 'investigatestyle',
    setStyle: {
        text: '表单模块',
        title: '表单模块',
        jsonData: [], //value1为sass显示内容，value2为前端显示内容
    },
})

componentProperties.set('tabBar', {
    component: 'tabBar',
    text: '底部导航',
    type: '1-5',
    active: true,
    style: 'tabBarStyle',
    setStyle: {
        text: '底部导航',
        activeColor: '#1989fa',
        inactiveColor: '#7d7e80',
        isShowBorder: true,
        iconWidth: '25',
        iconHeight: '25',
        fontSize: '14',
        Highlight: 0,
        iconList: [{
                /** 图标名称文字 */
                iconText: '商城',
                /** 图标图片 */
                iconPic: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mall01.png',
                inactive: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mallSelect01.png",
                /** 是否显示小圆点 */
                isDot: false,
            },
            {
                /** 图标名称文字 */
                iconText: '分类',
                /** 图标图片 */
                iconPic: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/type.png',
                inactive: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/typeSelect.png",
                /** 是否显示小圆点 */
                isDot: false,
            },
            {
                /** 图标名称文字 */
                iconText: '店铺',
                /** 图标图片 */
                iconPic: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mall02.png',
                inactive: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mallSelect02.png",
                /** 是否显示小圆点 */
                isDot: false,
            },
            {
                /** 图标名称文字 */
                iconText: '购物车',
                /** 图标图片 */
                iconPic: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mall03.png',
                inactive: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mallSelect03.png",
                /** 是否显示小圆点 */
                isDot: false,
            },
            {
                /** 图标名称文字 */
                iconText: '订单',
                /** 图标图片 */
                iconPic: 'https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mall04.png',
                inactive: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/yqhshetuan/directory/mallSelect04.png",
                /** 是否显示小圆点 */
                isDot: false,
            }
        ],
    },
})

componentProperties.set('follow', {
    component: 'follow',
    text: '关注公众号',
    type: '1-15',
    active: true,
    style: 'followStyle',
    setStyle: {
        text: '关注公众号',
        heade: 'http://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/icon/yimai.png',
        followName: '公众号名称',
        followAppId: '',
    },
})
componentProperties.set('associationInfo', {
    component: 'associationInfo',
    text: '社团信息',
    type: '1-19',
    active: true,
    style: 'associationInfostyle',
    setStyle: {
        text: '社团信息',
        borderRadius: 5,
        backgroundColor: '#fff',
        title: "社团简介",
        showDetalis: true,
        http: {},
    },
})

componentProperties.set('suspension', {
    component: 'suspension',
    text: '悬浮按钮',
    type: '1-16',
    active: true,
    style: 'associationSuspensionstyle',
    setStyle: {
        text: '悬浮按钮',
        img: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/photo/20230403/68e6f793b1359420fe212335936db0f8.png",
        http: {},
    },
})

export default componentProperties